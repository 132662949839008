<template>
  <div class="wrapper">
    <sideBar />
    <mainContent />
  </div>
</template>
<script>
// import sideBar from "../side-bar";
// import mainContent from "../main-content/mainContent.vue";

export default {
  name: "admin",
  components: {
    sideBar: () => import("../side-bar"),
    mainContent: () => import("../main-content/mainContent.vue"),
  },
  methods: {
    // changePassword() {
    //   this.$router.push({ name: "change-password" });
    // },
  },
};
</script>
<style scoped>
.wrapper {
  min-height: 100vh;
  position: relative;
}
/* main-content */
.main-content {
  position: relative;
  z-index: 2;
  float: right;
  width: calc(100% - 300px);
  min-height: 100%;
  overflow: auto;
  padding: 40px;
  background-color: #fff;
  height: calc(100vh - 78px);
  overflow: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 769px) {
  .main-content {
    width: calc(100% - 250px);
    /* height: calc(100vh - 42px); */
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 576px) {
  .main-content {
    width: 100%;
  }
}
</style>
